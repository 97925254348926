<template lang="html">
  <div class="content-box">
    <div class="title-area">
      <h2 class="title-b">설정 / 단말기 관리 / 단말기 수정</h2>
    </div>

    <div class="form-box mt-sm">
      <div class="form-item">
        <label class="form-title">단말기 명 <span class="requisite">*</span></label>
        <div class="form-element size-a">
          <input type="text" v-model="deviceName" placeholder="단말기 명" class="element-input size-lg">
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="deviceName==''">필수 입력 사항 입니다.</p>
        </div>
      </div>
      <div class="form-item">
        <label class="form-title">단말 ID (시리얼) <span class="requisite">*</span></label>
        <div class="form-element size-a">
          <input type="text" v-model="deviceID" placeholder="단말 ID" class="element-input size-lg" :readonly="true" />
        </div>
      </div>

      <!-- 현재 상태를 등록할때 필요 합니까?
      <div class="form-item">
        <label class="form-title">현재상태</label>
        <div class="form-element size-a">
          <input type="text" placeholder="" class="element-input size-lg" value="미확인" readonly>
        </div>
      </div> 
      -->

      <!-- 단말기 연럭처를 등록할때 필요 합니까? information 시 전송 받습니다.
      <div class="form-item">
        <label class="form-title">단말기 연락처</label>
        <div class="form-element size-a">
          <input type="text" placeholder="" class="element-input size-lg" value="미확인" readonly>
        </div>
      </div>
      -->

      <div class="form-item">
        <label class="form-title">고객사/운용부서 <span class="requisite">*</span></label>
        <div class="form-element size-a">
          <t-rich-select
            :options="companyList"
            class="border border-solid rounded-md text-gray-700 border-gray-300 outline-none w-62"
            textAttribute="companyName"
            valueAttribute="companyGuid"
            :hideSearchBox="false"
            v-model="companyGuid"
            :fixedClasses="richSelectXL"
          >
          </t-rich-select>
          <p class="mt-1 px-3 text-xxs text-red-600" v-if="companyGuid==''">필수 입력 사항 입니다.</p>
        </div>
      </div>
      <div class="form-item row-type">
        <label class="form-title">관리자 메모</label>
        <div class="form-element size-b">
          <textarea name="" v-model="deviceDescription" class="element-textarea" placeholder="메모"></textarea>
        </div>
      </div>
    </div>

    <div class="page-btn-area">
      <button type="button" class="element-btn bg-red size-lg" @click="cancelUpdate()">취소</button>
      <!-- <button type="button" class="element-btn size-lg bg-blue" @click="deviceUpdate()" :disabled="checkUpdateDisable()">저장</button> -->
      <button type="button" class="element-btn size-lg bg-blue" @click="deviceUpdate()">저장</button>
    </div>
  </div>
</template>

<script>
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"
import { richSelectXL } from "@/references/fixedClass"

export default {
  created () {
    this.getCompanyListAll()
    this.getDeviceInfo()
  },
  computed: {
    ...mapGetters(["getIsOverlay"]),
  },
  data () {
    return {
      richSelectXL,
      deviceGuid: '',
      deviceID: '',
      deviceName: '',
      companyGuid: '',
      deviceDescription: '',
      companyList: [],
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsAlert"]),
    async getCompanyListAll () {
      let reqObj = {}
      reqObj.target = "/company/listall";
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      this.companyList = []
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.companyList = result.msg.retData
      }
    },

    async getDeviceInfo () {
      let reqObj = {}
      reqObj.target = `/device/info/${this.$route.query.guid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        if(result.msg.retData == null) {
          this.chgIsAlert({status:true,string:'잘못된 접근 입니다.'})
          
          this.$router.push({name:'DeviceList'})
        }
        else {
          this.deviceName = result.msg.retData.deviceName
          this.deviceGuid = result.msg.retData.deviceGuid
          this.deviceID = result.msg.retData.deviceID
          this.companyGuid = result.msg.retData.companyGuid
          this.deviceDescription = result.msg.retData.deviceDescription
        }
        
      }
      else {
        this.chgIsAlert({status:true,string:'잘못된 접근 입니다.'})

        this.$router.push({name:'DeviceList'})
      }
    },
    
    checkUpdateDisable () {
      let retVal = true;
      if(this.deviceID !== "" && this.deviceName !== "" && this.companyGuid !== "") retVal = false;
      return retVal;
    },

    deviceUpdate () {
      if(this.deviceName === '') {
        this.chgIsAlert({status:true,string:'단말기 명을 입력해 주세요.'})
        return;
      }

      if(this.companyGuid === '') {
        this.chgIsAlert({status:true,string:'고객사/운용부서를 선택해 주세요.'})
        return;
      }

      this.deviceUpdateAction()
    },
    async deviceUpdateAction () {
      let reqObj = {}
      reqObj.target = `/device/update`;
      reqObj.method = "put";
      reqObj.params = {};
      reqObj.data = {
        "deviceGuid": this.deviceGuid,
        "companyGuid": this.companyGuid,
        "deviceDescription": this.deviceDescription,
        "deviceLocation": "",
        "deviceName": this.deviceName
      };
      reqObj.headers = { "accept":"application/json" };

      this.deviceCheck = false
      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.chgIsAlert({status:true,string:'수정 했습니다.'})

        this.$router.push({name:'DeviceAdmin'})
      }
    },
    cancelUpdate () {
      this.$router.push({name:'DeviceAdmin'})
    }
  }
  
}
</script>
<style lang="">
  
</style>